$(function() {

    $("a.nav__link").click(function() {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top - 150 + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });
})
